.searchbar {
  padding-top: 2rem;
}

.our-range {
  color: #555050;
  /* font-size: 42px; */
  font-weight: 600;
  letter-spacing: 0.75%;
  /* line-height: 66.36px; */
}
