.dropdown {
  color: #555050;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.75%;
}

.dropdown:hover .dropdown-menu {
  display: block;
  width: max-content;
}
