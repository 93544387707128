.adcontainer {
  max-width: 100%;
}

.left {
  background-image: linear-gradient(90deg, #0f3f79 0%, #00687e 134.58%);
  /* width: 50%; */
}
.right {
  /* width: 40%; */
  background-color: white;
}

.text {
  font-weight: 700;
  font-size: 42px;
}
.images {
  /* width: 175px; */
}

.contact-button {
  color: #0a4b7a;
  font-size: 24px;
  letter-spacing: 0.75%;
  line-height: 37.92px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .text {
    font-weight: 600;
    font-size: 32px;
  }
  .adcontainer {
    max-width: 100%;
  }
}
